import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import vuetify from './plugins/vuetify'

import Console from './utils/console'
import i18n from './i18n'
import { VueSvgIcon } from '@yzfe/vue-svgicon'
import '@yzfe/svgicon/lib/svgicon.css'

import './style/index.scss'

import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'
import { Swiper as SwiperClass, Pagination, Mousewheel, Autoplay } from 'swiper/core'
import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter' // 設置時區

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { parseUrlQuery, detectDevIsLineLiffMode } from './utils/liff'
import MetaInfo from 'vue-meta-info'

// -------------- LogRocket -------------- //
import LogRocket from 'logrocket'
if (process.env.NODE_ENV === 'master' && !location.hostname.includes('dream88')) LogRocket.init('iph-h5/iph_h5master')
if (process.env.NODE_ENV === 'dev') LogRocket.init('iph-h5/iph_h5dev')
if (process.env.NODE_ENV === 'staging') LogRocket.init('iph-h5/iph_h5staging')

// -------------- swiper -------------- //
SwiperClass.use([Pagination, Mousewheel, Autoplay])
Vue.use(getAwesomeSwiper(SwiperClass))
Vue.use(VueAwesomeSwiper /* { default options with global component } */)

// -------------- seo -------------- //
Vue.use(MetaInfo)

// -------------- dayjs --------------- //
dayjs.extend(utc)
dayjs.extend(timezone)
// 設置時區
dayjs.tz.setDefault(dayjs.tz.guess())
// dayjs.tz.setDefault('Asia/Shanghai')
// timezone => console.log(dayjs.tz().$x.$timezone)
Vue.prototype.$day = dayjs

Vue.config.productionTip = false

/**
 * 在 dev / rc 環境才會console
 * this.$log() / this.$error() / this.$warn()
 * @date 2021-03-04
 * @param {any} 傳入的任何型別
 */
Object.keys(Console).forEach(consoleEvents => {
  Vue.prototype[consoleEvents] = (...params) => {
    if (process.env.VUE_APP_CONSOLE === 'true') { return Console[consoleEvents](...params) }
  }
})

Vue.prototype.$scrollToTop = () => {
  window.scroll({
    top: 0,
    left: 0,
    behavior: 'smooth',
  })
}

Vue.component('Icon', VueSvgIcon)

const vue = new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app')

// alert(location.search)
// alert(parseUrlQuery(location.search).liff_Id)

// 如果為 liff 模式
if (detectDevIsLineLiffMode() || window.liff.isInClient()) {
  console.log(`Liff Mode: ${(process.env.VUE_APP_LINE_LIFF_MODE === 'true') || window.liff.isInClient()}`)
  console.log(`url search: ${location.search}`)
  console.log(`liff.state => ${new URLSearchParams(location.search).get('liff.state')}`)
  console.log(`url parse: ${JSON.stringify(parseUrlQuery(location.search))}`)
  console.log('[main.js] 執行 window.liff.init')
  console.log(parseUrlQuery(location.search).liff_Id)

  window.liff
    .init({
      liffId: detectDevIsLineLiffMode()
        ? process.env.VUE_APP_LINE_LIFF_ID
        : parseUrlQuery(location.search).liff_Id, // Use own liffId
    }).then(async() => {
      await store.commit('SET_LIFF_STATUS', detectDevIsLineLiffMode() || window.liff.isInClient())
      console.log('Liff INIT success')
    }).catch((err) => {
      console.log(err.code, err.message)
    })
}

export default vue
