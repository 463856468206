import { getWalletBalanceAPI } from '@/api'
import { spec } from '../../utils/money'

const state = () => ({
  main: '--',
  locked: '--',

  // wallet code: free_wallet
  free: {
    name: '--',
    balance: '--',
  },

  // wallet code: player_balance
  player: {
    name: '--',
    balance: '--',
  },

  // wallet code: locked_balance
  locked_wallet: {
    name: '--',
    balance: '--',
  },
})

const getters = {}

const mutations = {
  SET_MAIN_BALANCE(state, balance) {
    const balanceFormat = spec(balance)
    state.main = balanceFormat
  },

  SET_LOCKED_BALANCE(state, balance) {
    const balanceFormat = spec(balance)
    state.locked = balanceFormat
  },

  SET_FREE_BALANCE(state, data) {
    state.free.name = data.wallet.name
    state.free.balance = data.balance
  },

  SET_PLAYER_BALANCE(state, data) {
    state.player.name = data.wallet.name
    state.player.balance = data.balance
  },

  SET_LOCKED_WALLET_BALANCE(state, data) {
    state.locked_wallet.name = data.wallet.name
    state.locked_wallet.balance = data.balance
  },
}

const actions = {
  async get_user_balance({ commit }, walletCode) {
    const { walletcode } = walletCode
    const { data } = await getWalletBalanceAPI(walletCode)
    if (data) {
      if (walletcode === 'player_balance') {
        commit('SET_MAIN_BALANCE', data.balance)
      } else if (walletcode === 'locked_balance') {
        commit('SET_LOCKED_BALANCE', data.balance)
      }
      return data
    }
  },

  set_main_balance({ commit }, balance) {
    commit('SET_MAIN_BALANCE', balance)
  },
  set_locked_balance({ commit }, balance) {
    commit('SET_LOCKED_BALANCE', balance)
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
